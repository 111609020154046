<template>
    <div class="row error-404">
        <div class="col-12" id="bg">
            <div class="error-message">
                <img :src="require('@/assets/images/sorry-page.svg')" />
                <h2>Oops! It looks like something went wrong.</h2>
                <h4 class="mt-3">
                  <router-link :to="{ name: 'Index'}">
                      Click here
                  </router-link>
                  to return to your home page.
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Error 404',
    }
</script>

<style scoped>
    .error-message a {
        text-decoration: none;
    }

    .error-message a,
    .error-message a:visited,
    .error-message a:hover,
    .error-message a:active {
        color: #2F7FED;
    }

    .error-message img {
        width: 50%;
    }
</style>